import { useEffect, useState } from 'react'

import { Drawer, Accordion, Divider, Box } from '@mantine/core'
import cloneDeep from 'lodash.clonedeep'

import Button from 'src/components/Buttons/Button/Button'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import { ApplicantStage } from 'src/graphql/types/applicants'

import { ApplicantFiltersActionType } from '../../context/applicant-filters.reducer'
import {
  useFilters,
  useFiltersDispatch,
} from '../../context/ApplicantFiltersContext'
import { ApplicantStageFilter } from '../ApplicantStageFilter/ApplicantStageFilter'
import { MultiSelectFilter } from '../MultiSelectFilter/MultiSelectFilter'
import RangeFilter from '../RangeFilter/RangeFilter'
import { StepFilter } from '../StepFilter/StepFilter'

import { Filter, Filters, defaultFilters } from './Filters'

export interface RangeFilter extends Filter {
  lte: number
  gte: number
}

const FiltersSiderbar = ({
  openFilters,
  closeFilters,
}: {
  openFilters: boolean
  closeFilters: () => void
}) => {
  const filters = useFilters()

  const filtersDispatch = useFiltersDispatch()

  const [applicantStageOptions, setApplicantStageOptions] = useState(
    filters.applicantStages.options
  )

  const [yearsOfExperience, setYearsOfExperience] = useState<
    [number | null, number | null]
  >([null, null])

  const [callCount, setCallCount] = useState<[number | null, number | null]>([
    defaultFilters.callCount.min,
    defaultFilters.callCount.max,
  ])

  const [recruiterOptions, setRecruiterOptions] = useState([])

  const [positionOptions, setPositionOptions] = useState([])

  const [stepOptions, setStepOptions] = useState(filters.steps.options)

  useEffect(() => {
    setApplicantStageOptions(filters.applicantStages.options)
    setRecruiterOptions(filters.recruiters.options)
    setPositionOptions(filters.jobListings.options)
    setYearsOfExperience([
      filters.yearsOfExperience.gte ?? filters.yearsOfExperience.min,
      filters.yearsOfExperience.lte ?? filters.yearsOfExperience.max,
    ])
    setCallCount([
      filters.callCount.gte ?? filters.callCount.min,
      filters.callCount.lte ?? filters.callCount.max,
    ])
    setStepOptions(filters.steps.options)
  }, [filters])

  const onFilterClear = () => {
    setApplicantStageOptions(
      applicantStageOptions.map((option) => ({
        ...option,
        selected: false,
        options: option.options.map((statusOption) => ({
          ...statusOption,
          selected: false,
        })),
      }))
    )
    setYearsOfExperience([
      filters.yearsOfExperience.min,
      filters.yearsOfExperience.max,
    ])
    setCallCount([filters.callCount.min, filters.callCount.max])
    setPositionOptions(
      positionOptions.map((option) => ({ ...option, selected: false }))
    )
    setRecruiterOptions(
      recruiterOptions.map((option) => ({ ...option, selected: false }))
    )
    setStepOptions(
      stepOptions.map((stepOption) => ({
        ...stepOption,
        selected: false,
        options: stepOption.options.map((statusOption) => ({
          ...statusOption,
          selected: false,
        })),
      }))
    )
  }

  const onApplyFilters = () => {
    const filtersCopy: Filters = cloneDeep(filters)

    filtersCopy.applicantStages.options = applicantStageOptions
    filtersCopy.applicantStages.selectedValues = applicantStageOptions
      .filter((option) => option.selected)
      .flatMap((option) => {
        // Check if there are selected status options
        const selectedStatusOptions = option.options.filter(
          (statusOption) => statusOption.selected
        )

        if (selectedStatusOptions.length > 0) {
          // If there are selected status options, create StepFilter with both type and status
          return selectedStatusOptions.map((applicantStageOption) => {
            if (option.value === ApplicantStage.PROSPECT) {
              return {
                applicantStage: option.value,
                hiringStage: applicantStageOption.value,
              }
            }
            if (option.value === ApplicantStage.HIRED) {
              return {
                applicantStage: option.value,
              }
            }
            if (option.value === ApplicantStage.NOT_INTERESTED) {
              return {
                applicantStage: option.value,
                notInterestedReason: applicantStageOption.value,
              }
            }
            if (option.value === ApplicantStage.DISQUALIFIED) {
              return {
                applicantStage: option.value,
                disqualifiedReason: applicantStageOption.value,
              }
            }
            if (option.value === ApplicantStage.TERMINATED) {
              return {
                applicantStage: option.value,
                terminatedReason: applicantStageOption.value,
              }
            }
          })
        } else {
          // If there are no selected status options, create ApplicantStageFilter with only type
          return [
            {
              applicantStage: option.value,
            },
          ]
        }
      })

    if (yearsOfExperience[0] !== filtersCopy.yearsOfExperience.min) {
      filtersCopy.yearsOfExperience.gte = yearsOfExperience[0]
    }

    if (yearsOfExperience[1] !== filtersCopy.yearsOfExperience.max) {
      filtersCopy.yearsOfExperience.lte = yearsOfExperience[1]
    }

    if (callCount[0] !== filtersCopy.callCount.min) {
      filtersCopy.callCount.gte = callCount[0]
    }

    if (callCount[1] !== filtersCopy.callCount.max) {
      filtersCopy.callCount.lte = callCount[1]
    }

    filtersCopy.recruiters.options = recruiterOptions
    filtersCopy.recruiters.selectedValues = recruiterOptions
      .filter((item) => item.selected)
      .map((selected) => selected.value)

    filtersCopy.jobListings.options = positionOptions
    filtersCopy.jobListings.selectedValues = positionOptions
      .filter((item) => item.selected)
      .map((selected) => selected.value)

    filtersCopy.steps.options = stepOptions
    filtersCopy.steps.selectedValues = stepOptions
      .filter((stepOption) => stepOption.selected)
      .flatMap((stepOption) => {
        // Check if there are selected status options
        const selectedStatusOptions = stepOption.options.filter(
          (statusOption) => statusOption.selected
        )

        if (selectedStatusOptions.length > 0) {
          // If there are selected status options, create StepFilter with both type and status
          return selectedStatusOptions.map((statusOption) => ({
            type: stepOption.value,
            status: statusOption.value,
          }))
        } else {
          // If there are no selected status options, create StepFilter with only type
          return [
            {
              type: stepOption.value,
            },
          ]
        }
      })

    filtersDispatch({
      type: ApplicantFiltersActionType.FILTERS_CHANGED,
      payload: filtersCopy,
    })
    closeFilters()
  }

  return (
    <Drawer
      position="right"
      opened={openFilters}
      onClose={closeFilters}
      withCloseButton={false}
      size="24%"
    >
      <div className="h-[88vh] overflow-y-scroll">
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="text-sm font-semibold text-doubleNickel-gray-900">
            Filters
          </div>
          <Tag clickable showCloseButton={false}>
            <Box onClick={onFilterClear}> Clear All</Box>
          </Tag>
        </div>
        <Divider />
        <Accordion
          multiple
          defaultValue={[
            'Applicant Stage',
            'Hiring Stage',
            'Years of Experience',
            'Call count',
            'Recruiter',
            'Position',
            'Steps',
          ]}
          classNames={{
            root: 'px-0',
          }}
        >
          <ApplicantStageFilter
            filterOptions={applicantStageOptions}
            setFilterOptions={setApplicantStageOptions}
          />
          <StepFilter
            stepOptions={stepOptions}
            setStepOptions={setStepOptions}
          />
          <RangeFilter
            title={'Years of Experience'}
            min={defaultFilters.yearsOfExperience.min}
            max={defaultFilters.yearsOfExperience.max}
            step={0.5}
            minRange={0.5}
            value={yearsOfExperience}
            setValue={setYearsOfExperience}
          />
          <RangeFilter
            title={'Call count'}
            min={defaultFilters.callCount.min}
            max={defaultFilters.callCount.max}
            step={1}
            minRange={0}
            value={callCount}
            setValue={setCallCount}
          />
          <MultiSelectFilter
            title={'Recruiter'}
            checkedOptions={recruiterOptions}
            setCheckedOptions={setRecruiterOptions}
          />
          <MultiSelectFilter
            title={'Position'}
            checkedOptions={positionOptions}
            setCheckedOptions={setPositionOptions}
          />
        </Accordion>
      </div>

      <div className="flex h-[7vh] flex-row items-center justify-center gap-3">
        <Button
          onClick={onApplyFilters}
          text="Apply filters"
          className="mt-auto w-full"
        />
      </div>
    </Drawer>
  )
}

export default FiltersSiderbar
