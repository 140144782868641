import { useEffect, useState } from 'react'

import {
  Group,
  Drawer,
  Divider,
  Radio,
  Box,
  Tooltip,
  Anchor,
} from '@mantine/core'

import { navigate } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import Empty from 'src/components/Feedback/Empty/Empty'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { BULK_SMS } from 'src/graphql/activityLogs.graphql'
import { GET_TEXT_TEMPLATES } from 'src/graphql/textTemplates.graphql'
import IconPlane from 'src/icons/IconPlane'

import ApplicantList from './components/ApplicantList'
import RadioButton from './components/RadioButton'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const BulkSmsDrawer = ({
  applicants,
  setApplicants,
  isOpen,
  refetchQueries = [],
  onClose,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [showingApplicants, setShowingApplicants] = useState(false)
  const [bulkSmsResult, setBulkSmsResult] = useState(null)

  //QUERIES:
  const { data: textTemplatesList } = useQuery(GET_TEXT_TEMPLATES, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
      orderBy: {
        field: 'title',
        direction: 'asc',
      },
    },
  })

  const textTemplates = textTemplatesList?.textTemplates?.items || []

  // MUTATIONS:
  const [bulkSms, { data: bulkSmsData, loading: bulkSmsLoading }] = useMutation(
    BULK_SMS,
    {
      refetchQueries: refetchQueries,
      onCompleted: () => {
        toast('Text messages were sent successfully', 'success')
        // handleClose()
      },
      onError,
    }
  )

  useEffect(() => {
    if (bulkSmsData) {
      setBulkSmsResult(bulkSmsData.bulkSms.applicants)
    }
  }, [bulkSmsData])

  const handleClose = () => {
    setBulkSmsResult(null)
    onClose()
  }

  const handleSubmit = () => {
    setShowingApplicants(true)
    bulkSms({
      variables: {
        applicants: applicants.map((applicant) => applicant.applicantId),
        textTemplateId: selectedTemplateId,
      },
    })
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={handleClose}
      closeOnClickOutside={!bulkSmsLoading}
      closeOnEscape={!bulkSmsLoading}
      title={'Send Bulk Message'}
      classNames={{
        title: 'text-lg font-bold',
        content: 'flex flex-col h-full',
        body: 'flex flex-col justify-between flex-1 px-2 overflow-hidden',
      }}
      position="right"
    >
      <Drawer.Body>
        <div className="flex h-[93%] flex-col gap-5 overflow-auto">
          <div className="text-md flex flex-row items-center gap-4 font-medium text-doubleNickel-gray-900">
            Sending to: {applicants.length}{' '}
            {applicants.length === 1 ? 'applicant' : 'applicants'}
            <Box
              className="cursor-pointer text-sm font-semibold text-doubleNickel-brand-500 hover:underline"
              onClick={() => {
                setShowingApplicants(!showingApplicants)
              }}
            >
              {showingApplicants ? 'Hide all' : 'Show all'}
            </Box>
          </div>
          {showingApplicants && (
            <ApplicantList
              applicants={applicants}
              bulkSmsLoading={bulkSmsLoading}
              bulkSmsResult={bulkSmsResult}
              setApplicants={setApplicants}
            />
          )}
          <Divider />
          <div className="flex flex-col ">
            <div className="text-md font-medium text-doubleNickel-gray-900">
              Pick a template and send the bulk message:
            </div>
            <Radio.Group
              value={selectedTemplateId}
              onChange={setSelectedTemplateId}
            >
              {textTemplates.map((template) => (
                <RadioButton
                  key={template.textTemplateId}
                  template={template}
                  selectedTemplateId={selectedTemplateId}
                  setSelectedTemplateId={setSelectedTemplateId}
                />
              ))}
            </Radio.Group>
            {textTemplates.length === 0 && (
              <div className="flex flex-col items-center justify-center gap-6">
                <Empty
                  title="No templates found"
                  subtitle="Templates are required for bulk messages"
                />
                <Anchor
                  onClick={() => navigate('/settings?tab=sms')}
                  className="mt-auto"
                >
                  Add a template in settings
                </Anchor>
              </div>
            )}
          </div>
        </div>

        <Group className="absolute bottom-0 left-0 right-0 mt-auto grid grid-cols-2 gap-4 border-t bg-white p-4">
          <Button
            text="Cancel"
            variant="outline"
            onClick={handleClose}
            disabled={bulkSmsLoading}
          />
          <Tooltip
            disabled={selectedTemplateId && applicants.length > 0}
            label="Please select a template and at least one applicant to send the message"
          >
            <Button
              text={'Send'}
              variant="filled"
              lefticon={<IconPlane />}
              disabled={!selectedTemplateId || applicants.length === 0}
              loading={bulkSmsLoading}
              onClick={() => {
                handleSubmit()
              }}
            />
          </Tooltip>
        </Group>
      </Drawer.Body>
    </Drawer>
  )
}

export default BulkSmsDrawer
