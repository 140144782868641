import dayjs from 'dayjs'

import { navigate } from '@redwoodjs/router'
const DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss'

export function updateFiltersInUrl(filters) {
  const searchParams = new URLSearchParams(location.search)

  if (filters?.searchText?.searchText) {
    searchParams.set('searchText', filters.searchText.searchText)
  } else {
    searchParams.delete('searchText')
  }

  for (const filterKey in filters) {
    const filter = filters[filterKey]
    if (filter.type === 'multi-select') {
      if (filter.selectedValues?.length > 0) {
        searchParams.set(filter.key, filter.selectedValues)
      } else {
        searchParams.delete(filter.key)
      }
    }

    if (filter.type === 'range') {
      const values = {
        gte: filter.gte,
        lte: filter.lte,
      }
      if (values.gte || values.lte) {
        searchParams.set(filter.key, JSON.stringify(values))
      } else {
        searchParams.delete(filter.key)
      }
    }

    if (filter.type === 'date-range') {
      const values = {
        gte: filter.gte ? dayjs(filter.gte).format(DATE_FORMAT) : null,
        lte: filter.lte ? dayjs(filter.lte).format(DATE_FORMAT) : null,
      }
      if (values.gte || values.lte) {
        searchParams.set(filter.key, JSON.stringify(values))
      } else {
        searchParams.delete(filter.key)
      }
    }

    if (filter.type === 'step-filter') {
      if (filter.selectedValues?.length > 0) {
        const queryParamValue = filter.selectedValues.map(
          ({ type, status }) => {
            if (status) {
              return `${type}-${status}`
            }
            return type
          }
        )
        searchParams.set(filter.key, queryParamValue)
      } else {
        searchParams.delete(filter.key)
      }
    }
    if (filter.type === 'applicant-stage-filter') {
      if (filter.selectedValues?.length > 0) {
        const queryParamValue = filter.selectedValues.map((selectedValue) => {
          const { applicantStage } = selectedValue
          const subOption =
            selectedValue.hiringStage ||
            selectedValue.terminatedReason ||
            selectedValue.disqualifiedReason ||
            selectedValue.notInterestedReason
          if (subOption) {
            return `${applicantStage}-${subOption}`
          }
          return applicantStage
        })
        searchParams.set(filter.key, queryParamValue)
      } else {
        searchParams.delete(filter.key)
      }
    }
  }
  navigate(`?${searchParams.toString()}`, { replace: true })
}
