import React from 'react'
import { useState } from 'react'

import { Checkbox, Accordion, Badge } from '@mantine/core'

interface SelectedOption {
  [key: string]: string[]
}

export const ApplicantStageFilter = ({ filterOptions, setFilterOptions }) => {
  const initialize = (filterOptions) => {
    const selected = {}
    filterOptions.forEach((typeOption) => {
      if (typeOption.selected) {
        selected[typeOption.value] = typeOption.options
          .filter((statusOption) => statusOption.selected)
          .map((statusOption) => statusOption.value)
      }
    })
    return selected
  }

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption>(
    initialize(filterOptions)
  )

  const updatefilterOptions = (newSelectedSteps: SelectedOption) => {
    const updatedfilterOptions = filterOptions.map((typeOption) => {
      const isSelectedType = typeOption.value in newSelectedSteps
      const updatedTypeOption = {
        ...typeOption,
        selected: isSelectedType,
        options: typeOption.options.map((statusOption) => {
          const isSelectedStatus =
            isSelectedType &&
            newSelectedSteps[typeOption.value].includes(statusOption.value)
          return {
            ...statusOption,
            selected: isSelectedStatus,
          }
        }),
      }
      return updatedTypeOption
    })

    setFilterOptions(updatedfilterOptions)
  }

  const handleTypeChange = (type) => {
    setSelectedOptions((prevSelected) => {
      const newSelected = { ...prevSelected }

      if (newSelected[type]) {
        // If the type is already selected, toggle it off and remove its statuses
        delete newSelected[type]
      } else {
        // If the type is not selected, add it with an empty array for statuses
        newSelected[type] = []
      }

      updatefilterOptions(newSelected)
      return newSelected
    })
  }

  const handleStatusChange = (type, status, checked) => {
    setSelectedOptions((prevSelected) => {
      const selectedStatuses = prevSelected[type] || []
      const newSelectedStatuses = checked
        ? [...selectedStatuses, status]
        : selectedStatuses.filter((s) => s !== status)

      const newSelected = { ...prevSelected, [type]: newSelectedStatuses }

      updatefilterOptions(newSelected)
      return newSelected
    })
  }

  return (
    <Accordion.Item value="Applicant Stage">
      <Accordion.Control
        classNames={{
          control: 'p-0',
          label: 'text-sm font-semibold text-doubleNickel-gray-900',
        }}
      >
        <div className="flex flex-row items-center gap-2">
          Applicant Stage
          {filterOptions.reduce((count, option) => {
            const selectedStatusCount = option.selected
              ? option.options?.filter((status) => status.selected)?.length || 1
              : 0
            return count + selectedStatusCount
          }, 0) > 0 && (
            <Badge size="xs" color="gray" variant="light">
              {filterOptions.reduce((count, option) => {
                const selectedStatusCount = option.selected
                  ? option.options?.filter((status) => status.selected)
                      ?.length || 1
                  : 0
                return count + selectedStatusCount
              }, 0)}
            </Badge>
          )}
        </div>
      </Accordion.Control>
      <Accordion.Panel classNames={{ content: 'px-0' }}>
        <div className="flex flex-col gap-4">
          {filterOptions.map((typeOption) => (
            <div key={typeOption.value} className="flex flex-col gap-4">
              <Checkbox
                label={typeOption.label}
                checked={typeOption.selected}
                onChange={() => handleTypeChange(typeOption.value)}
              />
              {typeOption.value in selectedOptions &&
                typeOption.options.length > 0 && (
                  <div className="flex flex-col gap-4 px-5">
                    {typeOption.options.map((statusOption) => (
                      <Checkbox
                        key={statusOption.value}
                        label={statusOption.label}
                        checked={statusOption.selected}
                        onChange={(e) =>
                          handleStatusChange(
                            typeOption.value,
                            statusOption.value,
                            e.currentTarget.checked
                          )
                        }
                      />
                    ))}
                  </div>
                )}
            </div>
          ))}
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
