import { ActionIcon, Badge } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import IconClose from 'src/icons/IconClose'

export interface SelectedFilter {
  key: string
  label: string
  value: string
}

export const SelectedFiltersBar = ({
  selectedFilters = [],
  onRemoveFilter,
  onCleanFilters,
}: {
  selectedFilters: SelectedFilter[]
  onRemoveFilter: (filter: SelectedFilter) => void
  onCleanFilters: () => void
}) => {
  return (
    <div className="flex w-full flex-row items-center gap-x-4 gap-y-1 overflow-auto">
      {selectedFilters?.map((filter) => {
        return (
          <Tag
            key={`${filter.key}-${filter.value}`}
            onClose={() => onRemoveFilter(filter)}
          >
            {filter.label}
          </Tag>
        )
      })}
      {Object.keys(selectedFilters).length > 0 && (
        <div>
          <Button
            type="button"
            variant="subtle"
            text="Clear filters"
            onClick={() => onCleanFilters()}
          />
        </div>
      )}
    </div>
  )
}

export default SelectedFiltersBar
