import { Checkbox, Accordion, Badge } from '@mantine/core'
export type Option = {
  value: string
  label: string
  selected: boolean
}

export interface MultiSelectFilterProps {
  checkedOptions: Option[]
  title: string
  setCheckedOptions: (options: Option[]) => void
}

export const MultiSelectFilter = ({
  checkedOptions = [],
  title,
  setCheckedOptions,
}: MultiSelectFilterProps) => {
  const setChecked = (selectedOption) => {
    setCheckedOptions(
      checkedOptions.map((option) => {
        if (option.value == selectedOption.option) {
          return {
            ...option,
            selected: selectedOption.checked,
          }
        }
        return option
      })
    )
  }

  return (
    <Accordion.Item value={title}>
      <Accordion.Control
        classNames={{
          control: 'p-0 ',
          label: 'text-sm font-semibold text-doubleNickel-gray-900',
        }}
      >
        <div className="flex flex-row items-center gap-2">
          {title}
          {checkedOptions.filter((option) => option.selected).length > 0 && (
            <Badge size="xs" color="gray" variant="light">
              {checkedOptions.filter((option) => option.selected).length}
            </Badge>
          )}
        </div>
      </Accordion.Control>
      <Accordion.Panel classNames={{ content: 'px-0' }}>
        <div className="flex flex-col gap-4">
          {checkedOptions.map((option) => (
            <Checkbox
              key={option.value}
              checked={option.selected}
              onChange={(event) =>
                setChecked({
                  option: option.value,
                  checked: event.currentTarget.checked,
                })
              }
              label={option.label}
            ></Checkbox>
          ))}
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
