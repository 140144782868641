import { Badge, Loader } from '@mantine/core'

import Tag from 'src/components/DataDisplay/Tag/Tag'
import IconCheckmark from 'src/icons/IconCheckmark'
import { formatEnum } from 'src/lib/formatters'

const MetricList = ({
  selectedMetric,
  metrics = [],
  loading,
  onMetricClick,
}) => {
  // Define the desired order of metric names
  const order = ['LEAD', 'APPLICATION', 'QUALIFICATION', 'ONBOARDING']

  // Create a new array that includes all metrics with defaults
  const completeMetrics = order.map((metricName) => {
    // Find the metric in the props, if it exists
    const foundMetric = metrics.find(
      (metric) => metric.name.toUpperCase() === metricName.toUpperCase()
    )

    // Return the found metric or a default object with the name and value 0
    return foundMetric || { name: metricName, value: 0 }
  })

  return (
    <div className="flex flex-row items-center gap-4 ">
      <div className="text-left text-xs font-medium text-doubleNickel-gray-700">
        Suggested:
      </div>
      {completeMetrics.map((metric, index) => (
        <Tag
          key={index}
          clickable={true}
          onClick={() => onMetricClick(metric)}
          showCloseButton={false}
        >
          {selectedMetric?.name?.toUpperCase() == metric.name.toUpperCase() && (
            <IconCheckmark className="h-3 w-3 fill-none stroke-doubleNickel-brand-400" />
          )}
          {formatEnum(metric.name)}
          {loading ? (
            <Loader size={10} className="px-2" color="gray" />
          ) : (
            <div className="rounded-full bg-doubleNickel-gray-200 px-1 text-[10px] font-medium text-doubleNickel-gray-700">
              {metric.value}
            </div>
          )}
        </Tag>
      ))}
    </div>
  )
}

export default MetricList
